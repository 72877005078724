.line{
    position: fixed;
    width:5px;
    opacity:0;
    transition: all 0.5s;
    height:100%;
    background-color: rgba(255,255,255,0.2);
    position: absolute;
    top:0;
    left:30%;
    z-index: 1;
    .thumb{
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:15px;
        // background-color: rgba(255,255,255,1);
        background: linear-gradient(180deg, #ff7e5f, #feb47b);

        border-radius: 0 0 10px 10px ;
        z-index: 2;
        position: -webkit-sticky;
        position:sticky;
        top:0;
        height:calc(40vh);
    }
    .thumb:before{
        content:"";

    }

    &.visible{
        opacity: 1;
    }
}

@media (max-width:500px) {
    .line{
        .thumb{
            height:40vh
        }
    }
    
}
.entry {
    justify-content: center;
    padding: 64px;
    box-sizing: border-box;
    width: fit-content;  
    box-shadow: inset 0px 0px 50px rgba(0, 0, 0), inset 0px 0px 50px rgba(0, 0, 0),
    inset 0px 0px 50px rgba(0, 0, 0), inset 0px 0px 64px rgba(0, 0, 0);

    position: relative;
    flex-grow: 1;
    transition: all 0.5s; // Ensure all transitions happen on the entry
  
    gap: 50px;
  
    .bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: var(--background-color);
      height: 2px;
    }

    .point{
        position: absolute;
        top: 70px;
        left:20px;
        width: 15px;
        height: 15px;
        background-color: #4B4B4B;
        border-radius: 50%;
        z-index: 3;
        overflow:show;
        display:flex;
        align-items: flex-end;
        justify-content: center;
        .fill{
            opacity:0;
            width:5px;
            height:40px; 
            transform:translateY(-40px);
            background-color: #ff7e5f;
            transition: all 1s;
            border-radius: 50%;
        }
    }
  
    .entry-header {
      position: -webkit-sticky;
      position: sticky;
      top: 80px;
    }
  
    .smokescreen {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0);
      z-index: 1;
      opacity: 0.7;
      transition: opacity 1s ease-in-out;
  
      &.invisible {
        transition: opacity 1s ease-in-out; 
        opacity: 0;
        z-index: -1;
      }
    }
  
    .entry-content {
      transition: transform 0.5s ease-in-out; // Only transform should animate
      z-index: 2;
      transform: translateX(20px); // Starting translate

      .images{
            display:flex;
            flex-direction: column;
            gap:20px;
            .image-row{
                display:flex;
                gap:20px;
                img{
                    height:200px;
                    border-radius: 10px;
                }
            }
      }
  
      h3 {
        z-index: 2;
        transition: background 1s; // Ensure background gradient transition
        margin-top: 0;
        font-size: 25px;
        background: linear-gradient(45deg, var(--text-color), var(--text-color));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 22px;
  
        b {
            background: linear-gradient(45deg, var(--text-color), var(--text-color));
            -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
  
      b {
        font-size: 25px;
      }
  
      .link {
        display: flex;
        width: calc(20 * 32px);
        height: fit-content;
        border: 1px solid;
        border-color: hsla(0, 0%, 100%, .2);
        box-sizing: border-box;
        position: relative;
        background-color: black;
        padding: 20px;
  
        img {
          height: 100%;
        }
  
        .cross {
          position: absolute;
          height: 30px;
          width: 30px;
          opacity: 0.6;
  
          &.tl {
            top: -15px;
            left: -15px;
          }
  
          &.tr {
            top: -15px;
            right: -15px;
          }
  
          &.bl {
            bottom: -15px;
            left: -15px;
          }
  
          &.br {
            bottom: -15px;
            right: -15px;
          }
        }
      }
    }
  
    &.visible {
        .point{
            .fill{
                animation: fillPoint 0.5s forwards;
            }
        }
  
      .entry-content {
        transform: translateX(0); // Animate transform
  
        h3 b {
          background: linear-gradient(90deg, #ff7e5f, #feb47b);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }
  }
  
@media (max-width:500px) {
    .entry {
      width: 100%;
        padding-right:32px;
        padding-bottom:32px;
        padding-top:32px;
      .entry-content {
        .images{
            gap:10px;
            .image-row{
                gap:10px;

                img{
                    height:120px;
                    border-radius: 5px;
                }
            }
        }
        h3 {
          font-size: 18px;
        }
  
        b {
          font-size: 20px;
        }

        p{
            font-size: 14px;
        }
  
        .link {
          width: calc(100% - 32px);
        }
      }
    }
}

@keyframes fillPoint{
    0%{
        width:5px;
        height:40px;
        transform:translateY(-40px);
        border-radius:5px;
        background-color: #feb47b;
    }
    10%{
        opacity:1;
    }

    20%{
        width:3px;
    }

    40%{
        transform:translateY(-5px);
        opacity:1;
        height:50%;
        width:3px;
        background-color: #ff7e5f;
    }
    100%{
        border-radius: 50%;
        transform:translateY(0);
        opacity:1;
        width:100%;
        height:100%;
        
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root{
    --background-color: black;
    --text-color: rgba(255,255,255,0.85);
    --accent: #0D0D0D;
    --border: 2px solid rgba(255,255,255,0.1);  
}

html{
    background-color: var(--background-color);
}

body{
    background-color: var(--background-color);
    color: var(--text-color);
    position: relative;
}

.page{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width:100%;
}

h1,h2,h3,h4,h5,h6,p{
    color: var(--text-color);
    font-family:'Geist Sans';
}

p{
    font-size: 1rem;
    line-height:1.4;
    word-spacing: 1px;
}
.home {
    height:fit-content;
    background-color: black;
    z-index: 3;

    .line-animation{
        height:100%;
        width:5px;
        position: absolute;
        top:0;
        z-index: 0;
        .thumb{
            position: relative;
            display:grid;
            &::before{
                content:"";
                position: absolute;
                bottom:0px;
                width:10px;
                height:10px;
                border-radius: 50%;
                background-color: #feb47b;
                justify-self:center;
    
            }
            &.visible{
                height:100%;
                width:100%;
                background: linear-gradient(
                    180deg,              
                    #ff7e5f 0%,  
                    #ff7e5f 50%,   
                    #feb47b 97%,        
                    black 100%         
                  );              
                position: absolute;
                top:0;
                left:0;
                animation: lineAnimation 1s forwards cubic-bezier(.17,.67,.83,.67);
                &::before{
                    opacity: 0;
                }
            }   
        }
    
    }

    .hero {
        height: 100vh;
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        color: white;
        overflow: hidden;
        position: relative;
        box-shadow: inset 0px 0px 50px rgba(0,0,0),inset 0px 0px 50px rgba(0,0,0),inset 0px 0px 50px rgba(0,0,0),inset 0px 0px 64px rgba(0,0,0);
        z-index: 4;

        .hero-background{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            /* animation: opacity1 5s forwards; */
            -webkit-mask-image: radial-gradient(ellipse at center, transparent 20%, #000);
            mask-image: radial-gradient(ellipse at center, transparent 0%, #000);
            /* -webkit-mask-image: linear-gradient(180deg, #fff 5%, transparent 20%);
            mask-image: linear-gradient(180deg, #fff 5%, transparent 20%); */

            background-color: black;
        }

        .hero-content{
            margin-left:15%;
            h1{
                color:#CFCFCF;
                font-family:'Geist Sans';
                font-weight:900;
                font-size: 40px;
            }
            .scroll{
                animation: opacity1 2s forwards;
                opacity: 0;
                margin-top:50px;
                display:flex;
                gap:5px;
                align-items: center;
                p{
                    margin:0;   
                }

            }


        }

        
        .ripple {
            animation: rippleEffect 5s forwards;
            opacity: 0;
            position: absolute;
            align-self: center;
            justify-self: center;
            height: 3vw;
            aspect-ratio: 1/1;

            img {
                width: 100%;
                height: 100%;
            }


        }
        .hero-content{
            opacity:0;
            animation: opacity1 2s forwards;
            animation-delay:2s;

        }
    }

    .home-background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        animation: opacity 5s forwards;

    }

    .info{
        width:100%;
        z-index: 3;
        background-color: black;
        margin-top:50px;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction: column;
        gap:30px;
    }

}

@media (max-width:500px) {
    .home{
        .hero{
            box-shadow:inset 0px 0px 50px rgba(0,0,0);

            .hero-content{
                margin-left:50px;
                z-index: 4;
                padding-right: 40px;
                box-sizing: border-box;
                h1{
                    font-size:30px;
                }
                h3{
                    font-size:15px;
                }
                p{
                    font-size:14px;
                }
            }
        }
    }
    
}

@keyframes rippleEffect {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    25% {
        opacity: 0.7;
    }

    50% {
        /* transform: scale(2); */

    }

    75% {
        opacity: 0;
    }

    100% {
        transform: scale(50);
        opacity: 0;
    }
}

@keyframes opacity {
    0% {
        opacity: 0.02;
    }
    100% {
        opacity: 0.08;
    }
}


@keyframes opacity1 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes lineAnimation {
    0% {
        transform:translateY(-100%)
    }
    100% {
        transform:translateY(0)
    }
}
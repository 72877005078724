.experience-timeline{
    display:flex;
    flex-direction: column;
    // justify-content: center;    
    width:80%;
    gap:20px;
    margin-bottom:500px;
    .header{
        padding-left:10%;
        padding-right:5%;
        z-index: 3;
        background-color: black;
        padding-top:20px;
        padding-bottom:20px;

        box-shadow: 0 0 20px 50px rgba(0, 0, 0), inset 0px 0px 50px rgba(0, 0, 0), inset 0px 0px 50px rgba(0, 0, 0),
        inset 0px 0px 50px rgba(0, 0, 0), inset 0px 0px 64px rgba(0, 0, 0);
        .bar {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: var(--background-color);
            height: 2px;
          }
          h2{
            margin:0;
          }
    }
    .timeline{
        .line{
            width:30px;
            height:100%;
            position: absolute;
            top:0;
            left:30%;
        }
        display:flex;
        flex-direction: column;
        justify-content: center;    
        gap:20px;
        padding-left:10%;
        padding-right:5%;
        .timeline-item{
            display:flex;
            gap:30px;
            .col{
                padding-top:64px;
                box-sizing: border-box;
                width:15%;
                max-width: 15%;
                display:flex;
                flex-direction: column;
                position: relative;
                align-items: flex-end;
                // overflow: hidden;
            }
        }
        .timeline-marker{
            h3{
                margin:0;
                font-size:19px;
                color: rgba(255,255,255,0.2);
                text-wrap: nowrap;
                font-weight: bold;
                position: absolute;
                top:0;
                right:0;
            }
            position: -webkit-sticky;
            position: sticky;
            top:calc(40vh - 30px);
            max-width: 100%;
            right:0;
            transition: all 0.5s;
        }
        .timeline-marker.focused{
            color: rgba(255,255,255,0.6);
        }
    }
}

@media (max-width:500px) {
    .experience-timeline{
        
        width:100%;
        .timeline{
            padding-left: 0;
            padding-right:0;
            .timeline-item{
                gap:0;
                flex-direction: column;
                .col{
                    padding-top:0;
                    width:100%;
                    transform:translateY(15px);
                    z-index: 3;
                }
            }
            .timeline-marker{
                padding-left:84px;
                &.focused{
                    padding-left:64px
                }
            }

        }
    }
    
}